import "@ungap/custom-elements"
import EventSet from "./lib/EventSet"
import { getSession, goToLogin } from "./lib/Session"
import {ForbiddenError, NotFoundError, UnauthorizedError} from "./lib/error"
import { toggleLoading } from "./ui/loading"
import "./components/i18n"

const routes:Array<[RegExp, string]> = [
    [/^\/?$/, "./pages/home.js"],
    [/^\/_session/, "./pages/login.js"],
    [/^\/[^/.]*\/_design\/[^/.]*\/_view\/[^/.]*$/, "./pages/view.js"],
    [/^\/_new?$/, "./pages/item.js"],
    [/^\/([^/]+)\/([^/]+)\/?$/, "./pages/item.js"],
    [/^\/([^/]+)\/?$/, "./pages/database.js"],
]

const eventSets: {[key: string]: EventSet} = {}

let lastES:EventSet|null = null;

export async function updatePage(){
    toggleLoading(true)
    const sessionProm = getSession();

    let currentRoute = routes.find(
        ([reg]) => document.location.pathname.match(reg)
    )

    const main = document.getElementsByTagName("main")[0];
    main.innerHTML = "";

    let content = document.createTextNode("Not found");

    let ES:EventSet|null = null;
    let esRouteMatch = document.location.pathname.match(/^\/([^_][^/]*)\/?/)
    if(esRouteMatch){
        if(!eventSets[esRouteMatch[1]]){
            try {
            eventSets[esRouteMatch[1]] = await EventSet.fromPage()
            } catch(e){
                if(e instanceof UnauthorizedError){
                    await goToLogin()
                    return
                } else {
                    return fatalError(e)
                }
            }
        }
        ES = eventSets[esRouteMatch[1]]
    }

    if(ES != lastES){
        lastES = ES

        for(let it of document.querySelectorAll("[data-extra-style]")){
            it.remove();
        }

        if(ES){
            for(let it of ES.extraStyles){
                let el = document.createElement("link")
                el.rel = "stylesheet"
                el.href = it
                el.dataset.extraStyle = ES.name
                document.head.append(el)
            }
        }
    }

    const currentLocation = document.location.toString()
    if(currentRoute){
        const [regex, modPath] = currentRoute

        let [mod, session] = await Promise.all([
            import(modPath),
            sessionProm
        ])
        
        if(document.location.toString() != currentLocation){
            return
        }

        let args = document.location.pathname.match(regex) || []

        try {
            content = await mod.default({
                eventSet: ES,
                session,
                args: args.slice(1),
                url: new URL(document.location.toString())
            })
        } catch(e){
            return fatalError(e)
        }
    } else {
        return fatalError(new NotFoundError())
    }

    if(document.location.toString() != currentLocation){
        return
    }
    main.append(content)
    toggleLoading(false)
}

const FATAL_TEMPLATE = document.createElement("template")
FATAL_TEMPLATE.innerHTML = `
<section class="error">
    <h1>Fatal error</h1>
    <p>That wasn't expected</p>
    <hr/>
    <pre><out></out></pre>
</section>
`

const FATAL_NOTFOUND_TEMPLATE = document.createElement("template")
FATAL_NOTFOUND_TEMPLATE.innerHTML = `
<section class="error">
    <h1>Not found</h1>
    <p>The page you're looking for wasn't found</p>
</section>
`

const FATAL_FORBIDDEN_TEMPLATE = document.createElement("template")
FATAL_FORBIDDEN_TEMPLATE.innerHTML = `
<section class="error">
    <h1>Forbidden</h1>
    <p>You don't have access to this resource</p>
    <hr/>
    <p>You're currently logged in as <code class="username"></code>.
    Try to <a href="/_session/">login as a different user</a> or request access to this resource.</p>
</section>
`

async function fatalError(error){
    toggleLoading(false)
    console.error(error)
    document.body.dataset.fatal = "true"
    document.body.dataset.page = "fatal"
    const main = document.getElementsByTagName("main")[0] as HTMLElement;
    main.innerHTML = ""

    if(error instanceof ForbiddenError){
        let session = await getSession()
        const content = FATAL_FORBIDDEN_TEMPLATE.content.cloneNode(true) as DocumentFragment;
        document.title = "Forbidden - PV Upcoming"

        for(let el of content.querySelectorAll(".username")){
            el.textContent = session.userCtx?.name || "";
        }

        main.append(content)
    } else if(error instanceof NotFoundError) {
        document.title = "Not found - PV Upcoming"
        const content = FATAL_NOTFOUND_TEMPLATE.content.cloneNode(true) as DocumentFragment;
        main.append(content)
    } else {
        document.title = error.toString()+" - PV Upcoming"
        const content = FATAL_TEMPLATE.content.cloneNode(true) as DocumentFragment;
        (content.querySelector("out") as HTMLOutputElement).textContent = error.toString();
        main.append(content)
    }
}